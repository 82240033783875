<template>
  <div class="lead-details">
    <div class="header">
      <h5 class="title mb-0">Leads</h5>
      <div class="tabs">
        <button
          class="tab-button"
          :class="{ active: activeTab === 'steps' }"
          @click="setActiveTab('steps')"
        >
          Etapas
        </button>
        <button
          class="tab-button"
          :class="{ active: activeTab === 'origin' }"
          @click="setActiveTab('origin')"
        >
          Origem
        </button>
        <button
          class="tab-button"
          :class="{ active: activeTab === 'status' }"
          @click="setActiveTab('status')"
        >
          Status
        </button>
      </div>
    </div>
    <b-row v-if="loading">
      <b-col cols="12">
        <div class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
        </div>
      </b-col>
    </b-row>

    <ul v-else class="list-group">
      <li
        v-for="(lead, index) in leadData"
        :key="index"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <span>{{
          getLeadName(lead)
            ? getLeadName(lead)
            : lead.name.length
            ? lead.name
            : "-"
        }}</span>
        <span class="badge badge-secondary badge-pill">{{ lead.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";

export default {
  name: "LeadDetails",
  props: {
    fetchLeadData: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: "status",
    };
  },
  computed: {
    ...mapGetters({
      leadData: types.BALANCE_LEADDATA,
    })
  },
  setup() {
    return { toast: useToast() };
  },
  mounted() {
    this.fetchLeadData(this.activeTab); // Busca inicial ao montar
  },
  methods: {
    setActiveTab(tabName) {
      if (this.activeTab !== tabName) {
        this.activeTab = tabName;
        this.fetchLeadData(tabName); // Busca novos dados ao mudar a aba
      }
    },

    getLeadName(lead) {
      return this.activeTab === "origin"
        ? this.$options.filters.leadOrigin(lead.name)
        : lead.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1520px) {
    & {
      justify-content: center;
      flex-wrap: wrap;

      h5 {
        margin-bottom: 1rem !important;
      }
    }
  }
}

.tabs {
  display: flex;
  background-color: #22283b;
  border-radius: 10px;
}

.tab-button {
  background-color: transparent;
  border: none;
  color: #cbd5e1;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.2s;
}

.tab-button:hover {
  background-color: #334155;
}

.tab-button.active {
  background-color: #ea580c;
  color: #fff;
}

.lead-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lead-icon {
  margin-right: 8px;
}

.lead-name {
  flex-grow: 1;
  font-weight: bold;
  color: #fff;
}

.lead-value {
  font-weight: 500;
}
</style>
