<template>
  <div>
    <new-lead-sidebar />
    <import-leads-sidebar />
    <new-appointment-sidebar />
    <job-sample-settings-sidebar />
    <b-card no-body>
      <b-card-body>
        <b-row>
          <div
            class="w-100"
            :class="{ 'd-flex justify-content-between': width > 1300 }"
          >
            <div class="d-flex align-items-center ml-2 mb-2 mr-2">
              <b-form-input
                id="keyword"
                class="name-search"
                placeholder="Pesquisar"
                v-model="general"
                @keyup.enter="search"
              />
              <div class="divider"></div>
              <b-form-checkbox
                v-model="selected"
                @change="multipleSelect"
                class="select-all-checkbox"
              />
              <b-dropdown
                variant="flat"
                no-caret
                left
                boundary="window"
                toggle-class="recruitment-select-drowpdown"
              >
                <template #button-content>
                  <feather-icon
                    icon="ChevronDownIcon"
                    style="cursor: pointer"
                  />
                </template>
                <b-dropdown-item @click="multipleSelect('all')">
                  <span class="align-middle ml-50">Selecionar todos</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect('none')">
                  <span class="align-middle ml-50">Nenhum</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(1)">
                  <span class="align-middle ml-50">Leads</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(2)">
                  <span class="align-middle ml-50">Road show</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(3)">
                  <span class="align-middle ml-50">Dinâmica de grupo</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(4)">
                  <span class="align-middle ml-50">Entrevista individual</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(5)">
                  <span class="align-middle ml-50">Pré-Job Sample</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(6)">
                  <span class="align-middle ml-50">Job sample</span>
                </b-dropdown-item>
                <b-dropdown-item @click="multipleSelect(7)">
                  <span class="align-middle ml-50">Aprovação final</span>
                </b-dropdown-item>
              </b-dropdown>
              <div class="divider"></div>
              <div class="d-flex align-items-center">
                <span class="mr-1">Etapas:</span>
                <v-select
                  class="recruitment-stages-select"
                  v-model="stage"
                  :reduce="(stage) => stage.id"
                  :options="stages"
                  :loading="loading.stages"
                  :close-on-select="false"
                  label="name"
                  @keyup.enter="search"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </div>
              <div class="divider"></div>
              <div class="d-flex align-items-center">
                <span class="mr-1">Ações:</span>
                <div class="text-center d-flex align-items-center">
                  <b-button
                    id="recruitment-lead-hot"
                    class="btn-icon mr-1"
                    variant="flat"
                    style="padding: 0"
                    @click="setStatus(leadStatus.CUSTOMER_STATUS_LEAD_PN_HOT)"
                  >
                    <!-- quente ativo -->
                    <img
                      class="img_action"
                      src="~@/assets/images/svg/icn_hot_active.svg"
                    />
                  </b-button>
                  <b-tooltip target="recruitment-lead-hot" triggers="hover">
                    Esquentar
                  </b-tooltip>
                  <b-button
                    id="recruitment-lead-cold"
                    class="btn-icon mr-1"
                    variant="flat"
                    style="padding: 0"
                    @click="setStatus(leadStatus.CUSTOMER_STATUS_LEAD_PN_COLD)"
                  >
                    <!-- frio ativo -->
                    <img
                      class="img_action"
                      src="~@/assets/images/svg/icn_cold_active.svg"
                    />
                  </b-button>
                  <b-tooltip target="recruitment-lead-cold" triggers="hover">
                    Esfriar
                  </b-tooltip>
                  <b-button
                    id="recruitment-lead-disinterested"
                    class="btn-icon mr-1"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      setStatus(
                        leadStatus.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED
                      )
                    "
                  >
                    <!-- desinteressado ativo -->
                    <img
                      class="img_action"
                      src="~@/assets/images/svg/icn_disinterested_active.svg"
                    />
                  </b-button>
                  <b-tooltip
                    target="recruitment-lead-disinterested"
                    triggers="hover"
                  >
                    Desinteressado
                  </b-tooltip>
                  <b-button
                    id="recruitment-lead-appointment"
                    class="btn-icon mr-1"
                    variant="flat"
                    style="padding: 0"
                    @click="newAppoitment"
                  >
                    <feather-icon icon="CalendarIcon" size="16" />
                  </b-button>
                  <b-tooltip
                    target="recruitment-lead-appointment"
                    triggers="hover"
                  >
                    Agendar compromisso
                  </b-tooltip>
                  <b-button
                    id="recruitment-lead-stage-update"
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="openStageUpdateModal"
                  >
                    <feather-icon icon="CheckSquareIcon" size="16" />
                  </b-button>
                  <b-tooltip
                    target="recruitment-lead-stage-update"
                    triggers="hover"
                  >
                    Mudar etapa
                  </b-tooltip>
                </div>
              </div>
              <div class="divider"></div>
              <template v-if="width > 800">
                <div class="d-flex align-items-center">
                  <img class="star-action" :src="favoriteIcon" alt="star" />
                  <b-dropdown
                    variant="flat"
                    no-caret
                    left
                    boundary="window"
                    toggle-class="recruitment-select-drowpdown"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="ChevronDownIcon"
                        style="cursor: pointer"
                      />
                    </template>
                    <b-dropdown-item @click="selectFavorite(true)">
                      <span class="align-middle ml-50"
                        >Mostrar somente favoritos</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item @click="selectFavorite(false)">
                      <span class="align-middle ml-50">Mostrar todos</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              <template v-if="width > 900">
                <div class="divider"></div>
                <div class="d-flex align-items-center">
                  <feather-icon icon="SettingsIcon" size="16" />
                  <b-dropdown
                    variant="flat"
                    no-caret
                    left
                    boundary="window"
                    toggle-class="recruitment-select-drowpdown"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="ChevronDownIcon"
                        style="cursor: pointer"
                      />
                    </template>
                    <b-dropdown-item @click="openSettingsSidebar()">
                      <span class="align-middle ml-50"
                        >Personalizar {{ view }}</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canUpdateJobSampleDate"
                      @click="openJobSampleSettingsSidebar()"
                    >
                      <span class="align-middle ml-50"
                        >Editar data do Job Sample</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </div>
            <div
              class="d-flex align-itens-center"
              :class="{ 'ml-2 mb-2': width < 1300 }"
            >
              <b-button
                variant="outline-secondary"
                class="mr-1 btn-import"
                @click="importLeads"
              >
                <feather-icon icon="UploadIcon" class="mr-1" />
                Importar
              </b-button>
              <b-button variant="success" class="mr-1 btn-new" @click="newLead">
                <feather-icon icon="PlusCircleIcon" class="mr-1" />
                <span style="text-wrap: nowrap">Novo lead</span>
              </b-button>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="block-divider mt-2 mb-2"></div>
        </b-row>
        <template v-if="expanded">
          <b-row>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group label="Nome" label-for="searchName">
                <b-form-input
                  id="searchName"
                  v-model="name"
                  @keyup.enter="search"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group label="Status" label-for="searchStatus">
                <v-select
                  id="searchStatus"
                  multiple
                  v-model="status"
                  :reduce="(status_list) => status_list.id"
                  :options="statusList"
                  :loading="loading.statusList"
                  label="name"
                  @input="filterSelected = undefined"
                  @keyup.enter="search"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group label="Origem" label-for="searchSource">
                <v-select
                  id="searchSource"
                  multiple
                  v-model="origin"
                  :reduce="(origins) => origins.key"
                  :options="origins"
                  :loading="loading.origin"
                  label="name"
                  @keyup.enter="search"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group label="Franqueado" label-for="searchFranchise">
                <v-select
                  id="searchFranchise"
                  multiple
                  v-model="franchise"
                  :reduce="(franchise) => franchise.id"
                  :options="franchises"
                  :loading="loading.franchises"
                  label="name"
                  @keyup.enter="search"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group label="Potencial" label-for="searchPotential">
                <v-select
                  id="searchPotential"
                  multiple
                  v-model="potential"
                  :reduce="(potential) => potential.key"
                  :options="potentials"
                  :loading="loading.potentials"
                  label="name"
                  @keyup.enter="search"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group label="Data de criação" label-for="searchCreatedAt">
                <flat-pickr
                  id="searchCallReminder"
                  v-model="createdAt"
                  class="form-control"
                  :config="flatPickrConfig"
                  :placeholder="`01 Jan 2020 até ${generateTodayDate()}`"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group
                label="Data para lembrar"
                label-for="searchCallReminder"
              >
                <flat-pickr
                  id="searchCallReminder"
                  v-model="callReminder"
                  class="form-control"
                  :config="flatPickrConfig"
                  :placeholder="`01 Jan 2020 até ${generateTodayDate()}`"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="mb-1">
              <b-form-group
                label="Tipo de visualização"
                label-for="searchStructureOption"
              >
                <v-select
                  id="searchStructureOption"
                  v-model="structureOption"
                  :reduce="(structure_list) => structure_list.key"
                  :options="structures"
                  :loading="loading.structures"
                  label="name"
                  @keyup.enter="search"
                  :clearable="false"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <div class="block-divider mt-2 mb-2"></div>
          </b-row>
        </template>
        <b-row>
          <div
            class="w-100"
            :class="{ 'd-flex justify-content-between': width > 1300 }"
          >
            <div class="d-flex align-items-center ml-2">
              <span class="mr-2">Filtros rápidos:</span>
              <div class="filters-container mr-1">
                <div
                  class="filter-btn"
                  :class="{
                    active:
                      filterSelected === leadStatus.CUSTOMER_STATUS_LEAD_PN_HOT,
                  }"
                  @click="selectFilter(leadStatus.CUSTOMER_STATUS_LEAD_PN_HOT)"
                >
                  Quente
                  <feather-icon icon="XIcon" class="ml-1" />
                </div>
              </div>
              <div class="filters-container mr-1">
                <div
                  class="filter-btn"
                  :class="{
                    active:
                      filterSelected ===
                      leadStatus.CUSTOMER_STATUS_LEAD_PN_COLD,
                  }"
                  @click="selectFilter(leadStatus.CUSTOMER_STATUS_LEAD_PN_COLD)"
                >
                  Frio
                  <feather-icon icon="XIcon" class="ml-1" />
                </div>
              </div>
              <div class="filters-container">
                <div
                  class="filter-btn"
                  :class="{
                    active:
                      filterSelected ===
                      leadStatus.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED,
                  }"
                  @click="
                    selectFilter(
                      leadStatus.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED
                    )
                  "
                >
                  Desinteressado
                  <feather-icon icon="XIcon" class="ml-1" />
                </div>
              </div>
            </div>
            <div
              class="d-flex align-itens-center"
              :class="{ 'ml-2 mt-2': width < 1300 }"
            >
              <b-button
                variant="outline-secondary"
                class="mr-1 btn-expand"
                @click="expanded = !expanded"
              >
                <feather-icon icon="FilterIcon" class="mr-1" />
                Expandir filtros
              </b-button>
              <b-button
                variant="outline-secondary"
                class="mr-1 btn-new"
                :disabled="loadingSearch"
                @click="clearFilters"
              >
                Limpar
              </b-button>
              <b-button
                variant="primary"
                class="mr-1 btn-new"
                :disabled="loadingSearch"
                @click="fetchData"
              >
                <b-spinner v-if="loadingSearch" small class="mr-1" />
                <feather-icon v-else icon="SearchIcon" class="mr-1" />
                Pesquisar
              </b-button>
            </div>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      id="stage-modal"
      centered
      ok-title="Confirmar"
      cancel-title="Cancelar"
      dialog-class="recruitment-stage-modal"
      v-model="modalRecruitmentStage.visible"
      @ok="updateLeadRecruitmentStage"
    >
      <b-card-text>
        <div class="text-center mb-2">
          <h3>Confirmar alteração de etapa</h3>
          <br />
          Você está preste a alterar a etapa de todos os candidatos
          selecionados. Atenção esta ação não poderá ser desfeita.
        </div>
        <b-col cols="12" md="6" class="mb-1">
          <span class="mr-1">Nova etapa:</span>
          <v-select
            class="recruitment-new-stage-select"
            v-model="newStage"
            :reduce="(stage) => stage.id"
            :options="newStagesAvailable"
            :loading="loading.stages"
            :close-on-select="false"
            label="name"
          >
            <template #no-options="{ search, searching, loading }">
              Sem resultados
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" class="mb-1">
          <b-table
            :items="customersSelected"
            :fields="modalTableColumns"
            responsive
          >
            <template #cell(actions)="data">
              <div class="text-wrap">
                <b-button-group>
                  <b-button
                    :id="`unselect-recruitment-lead` + data.item.customer_id"
                    class="btn-icon mr-1"
                    variant="flat"
                    style="padding: 0"
                    @click="unselectRecruitmentLead(data.item.customer_id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                  <b-tooltip
                    :target="`unselect-recruitment-lead` + data.item.customer_id"
                    triggers="hover"
                  >
                    Remover lead
                  </b-tooltip>
                </b-button-group>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as leadTypes from "@/modules/lead/store/types";
import { OPEN_NEW_APPOINTMENT_SIDEBAR } from "@/modules/appointment/store/types";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useAppConfig from "@core/app-config/useAppConfig";
import vSelect from "vue-select";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import flatPickr from "vue-flatpickr-component";
import { dateToShortFormat } from "@/helpers/converters";
import * as leadStatus from "@/constants/customers_status";
import { useWindowSize } from "@vueuse/core";
import NewLeadSidebar from "@/modules/lead/components/NewLeadSidebar.vue";
import { LEAD_TYPE_PPN } from "@/constants/lead";
import ImportLeadsSidebar from "./ImportLeadsSidebar.vue";
import NewAppointmentSidebar from "@/modules/appointment/components/NewAppointmentSidebar.vue";
import { STRUCTURE } from "@/constants/structure_options";
import JobSampleSettingsSidebar from "./JobSampleSettingsSidebar.vue";
import { FINAL_APPROVAL } from "@/constants/recruitment_stages";
import { MANAGE_ACTION, JOB_SAMPLE_DATE } from "@/constants/resources";

export default {
  components: {
    vSelect,
    flatPickr,
    NewLeadSidebar,
    ImportLeadsSidebar,
    NewAppointmentSidebar,
    BModal,
    JobSampleSettingsSidebar,
  },
  data() {
    return {
      loading: {
        stages: false,
        statusList: false,
        origins: false,
        franchises: false,
        potentials: false,
        structures: false,
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      expanded: false,
      general: undefined,
      name: undefined,
      selected: false,
      stage: undefined,
      status: [],
      origin: undefined,
      franchise: undefined,
      potential: undefined,
      createdAt: undefined,
      callReminder: undefined,
      structureOption: STRUCTURE,
      filterSelected: undefined,
      favorite: false,
      newStage: undefined,
      modalTableColumns: [
        { key: "name", label: "Nome" },
        { key: "stage_name", label: "Etapa atual" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  props: {
    fetchData: {
      type: Function,
      default: () => {},
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: "kanban",
    },
  },
  setup() {
    const { skin } = useAppConfig();
    const { width } = useWindowSize();
    return {
      toast: useToast(),
      skin,
      dateToShortFormat,
      leadStatus,
      width,
    };
  },
  directives: {
    "b-modal": VBModal,
  },
  computed: {
    ...mapGetters({
      statusList: sharedTypes.LEAD_PN_STATUSES,
      franchises: sharedTypes.FRANCHISES,
      potentials: sharedTypes.LEAD_POTENTIALS,
      origins: sharedTypes.LEAD_ORIGINS,
      structures: sharedTypes.STRUCTURE_TYPES,
      stages: types.RECRUITMENT_STAGES,
      leadsSelected: types.LEADS_SELECTED,
      recruitmentKanban: types.RECRUTMENT_KANBAN,
      modalRecruitmentStage: types.MODAL_RECRUITMENT_STAGE,
    }),
    favoriteIcon: function () {
      return this.favorite
        ? require("../../../assets/images/svg/icn_star_active.svg")
        : require("../../../assets/images/svg/icn_star_inactive.svg");
    },
    customersSelected: function () {
      if (this.leadsSelected.length > 0) {
        return _.chain(this.recruitmentKanban)
          .flatMap("recruitments")
          .filter(({ customer_id }) => this.leadsSelected.includes(customer_id))
          .map(({ id, customer_id, customer, stage_name, stage_order }) => ({
            id,
            customer_id,
            name: customer.name,
            stage_name,
            stage_order,
          }))
          .value();
      }
      return [];
    },
    newStagesAvailable: function () {
      if (this.leadsSelected.length > 0) {
        const minOrder = _.chain(this.customersSelected)
          .map("stage_order")
          .max()
          .value();
        const maxOrder = FINAL_APPROVAL.id;
        return _.chain(this.stages)
          .filter(({ order }) => order > minOrder && order <= maxOrder)
          .value();
      }
      return [];
    },
    canUpdateJobSampleDate: function () {
      return this.$can(MANAGE_ACTION, JOB_SAMPLE_DATE);
    },
  },
  mounted() {
    this.selectMultiplesLeads("none");
    this.loading.statusList = true;
    this.getStatuses()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.statusList = false;
      });
    this.loading.origins = true;
    this.getOrigins({ leadType: LEAD_TYPE_PPN })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as origens para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.origins = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.potentials = true;
    this.getPotentials()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os potenciais para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.potentials = false;
      });
    this.loading.structures = true;
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os tipos de visualização para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });
    this.loading.stages = true;
    this.getStages()
      .catch((err) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as etapas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.stages = false;
      });
  },
  methods: {
    ...mapActions({
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getPotentials: sharedTypes.GET_LEAD_POTENTIALS,
      getStatuses: sharedTypes.GET_LEAD_PN_STATUSES,
      getStages: types.GET_RECRUITMENT_STAGES,
      openNewLeadSidebar: leadTypes.OPEN_NEW_LEAD_SIDEBAR,
      openImportLeadsSidebar: types.OPEN_IMPORT_LEADS_SIDEBAR,
      openNewAppointmentSidebar: OPEN_NEW_APPOINTMENT_SIDEBAR,
      updateLeadsStatus: types.UPDATE_LEADS_STATUS,
      getOrigins: sharedTypes.GET_LEAD_ORIGINS,
      updateRecruitmentLeadsStage: types.UPDATE_RECRUITMENT_LEAD_STAGE,
      openModalRecruitmentStage: types.OPEN_MODAL_RECRUITMENT_STAGE,
      openSettingsTableKanbanSidebar: types.OPEN_SETTINGS_TABLE_KANBAN_SIDEBAR,
      openJobSampleSettingsSidebar: types.OPEN_JOB_SAMPLE_SETTINGS_SIDEBAR,
    }),
    ...mapMutations({
      selectMultiplesLeads: types.SELECT_MULTIPLE_LEADS,
      toggleLeadSelection: types.TOGGLE_LEAD_SELECTION,
      mutateModalRecruitmentStage: types.MUTATE_MODAL_RECRUITMENT_STAGE,
    }),
    importLeads() {
      this.openImportLeadsSidebar({
        saveAction: () => {
          this.fetchData();
        },
      });
    },
    newLead() {
      this.openNewLeadSidebar({
        id: undefined,
        leadType: LEAD_TYPE_PPN,
        saveAction: () => {
          this.fetchData();
        },
      });
    },
    openSettingsSidebar() {
      this.openSettingsTableKanbanSidebar({
        currentPage: this.currentPage ? this.currentPage : 1,
      });
    },
    selectFilter(filter) {
      if (this.filterSelected === filter) {
        this.status = [];
        this.filterSelected = undefined;
      } else {
        this.filterSelected = filter;
        this.status = [];
        this.status.push(filter);
      }
      this.fetchData();
    },
    setStatus(status) {
      if (this.leadsSelected.length > 0) {
        this.$swal({
          title: "Confirmação",
          text: `Deseja alterar o status dos leads selecionados?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer:
              "text-center " + `${this.skin === "dark" ? "text-white" : ""}`,
          },
        }).then((result) => {
          if (result.value) {
            this.updateStatus(status);
          }
        });
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção",
            text: "Selecione ao menos um lead para alterar o status.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    },
    updateStatus(status) {
      this.updateLeadsStatus({
        customer_ids: this.leadsSelected,
        status_id: status,
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Status alterado com sucesso.",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.selectMultiplesLeads("none");
          this.fetchData();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao alterar o status. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    generateTodayDate() {
      return this.dateToShortFormat(new Date());
    },
    newAppoitment() {
      if (this.leadsSelected.length > 0) {
        const customers = _.map(
          this.customersSelected,
          ({ customer_id, name }) => ({
            id: customer_id,
            name,
          })
        );
        this.openNewAppointmentSidebar({
          customers,
          saveAction: () => {
            this.selectMultiplesLeads("none");
            this.fetchData();
          },
        });
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção",
            text: "Selecione ao menos um lead para agendar o compromisso.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    },
    multipleSelect(event){
      if(event === true){
        this.selectMultiplesLeads('all')
      }if(event === false || event === 'none'){
        this.selected = false
        this.selectMultiplesLeads('none')
      }else{
        this.selected = true
        this.selectMultiplesLeads(event)
      }
    },
    selectFavorite(favorite) {
      this.favorite = favorite;
      this.fetchData();
    },
    unselectRecruitmentLead(customer_id) {
      this.toggleLeadSelection(customer_id);
    },
    updateLeadRecruitmentStage(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.customersSelected.length > 0 && this.newStage) {
        const recruitment_customer_ids = _.map(this.customersSelected, "id");
        this.updateRecruitmentLeadsStage({
          recruitment_customer_ids,
          new_stage_id: this.newStage,
        })
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "Etapa alterada com sucesso.",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.mutateModalRecruitmentStage({ visible: false });
            this.selectMultiplesLeads("none");
            this.newStage = undefined;
            this.fetchData();
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao alterar a etapa. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção",
            text: "Selecione ao menos um lead e etapa para seguir com a alteração.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    },
    openStageUpdateModal() {
      if (this.leadsSelected.length > 0) {
        this.mutateModalRecruitmentStage({ visible: true });
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção",
            text: "Selecione ao menos um lead para alteração de etapa.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    },
    clearFilters() {
      this.general = undefined;
      this.name = undefined;
      this.selected = false;
      this.stage = undefined;
      this.status = [];
      this.origin = undefined;
      this.franchise = undefined;
      this.potential = undefined;
      this.createdAt = undefined;
      this.callReminder = undefined;
      this.structureOption = STRUCTURE;
      this.filterSelected = undefined;
      this.favorite = false;
      this.fetchData();
    },
  },
};
</script>
<style lang="scss" scoped>
.name-search {
  height: 30px;
  border-radius: 0.5rem;
}
.name-search-icon {
  position: absolute;
  top: 8px;
  left: 180px;
  cursor: pointer;
}
.divider {
  width: 5px;
  height: 20px;
  background-color: #6e6b7b;
  margin: 3px 10px;
}
.select-all-checkbox {
  padding-left: 1.5rem !important;
}
.img_action {
  width: 16px;
  height: 16px;
}

.star-action {
  width: 18px;
  height: 18px;
}

.block-divider {
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
}
.dark-layout .block-divider {
  background-color: #1b2132;
}
.filters-container {
  display: flex;
  align-items: center;
}
.filter-btn {
  display: flex;
  align-items: center;
  background-color: #dcdcdc;
  padding: 8px 15px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  height: 25px;
}
.dark-layout .filter-btn {
  background-color: #1b2132;
}
.filter-btn:hover,
.filter-btn.active {
  background-color: #e65100;
}
.filter-btn .close {
  margin-left: 8px;
  cursor: pointer;
  font-size: small;
}
.btn-import {
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #00cfe8 !important;
  color: #00cfe8;
}
.btn-expand {
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #546e7a !important;
  color: #546e7a;
}
.btn-new {
  height: 30px;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.recruitment-select-drowpdown {
  padding: 0px !important;
}
.recruitment-stages-select {
  width: 200px;
  .vs__dropdown-toggle {
    height: 30px;
    border-radius: 0.5rem;
  }
  .vs__selected-options {
    overflow: hidden;
  }
}

.recruitment-stage-modal {
  max-width: 650px !important;
}
</style>
