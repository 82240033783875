<template>
  <div>
    <b-sidebar
      id="sidebar-job-sample-settings"
      sidebar-class="sidebar-lg"
      :visible="jobSampleSettingsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateJobSampleSettingsSidebar({ visible: val })"
      @hidden="clear"
      @shown="onShow"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Data do próximo Job Sample</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="12" class="mb-1">
              <small>A data informada será adicionada no e-mail de Job Sample enviado para os candidatos</small>
            </b-col>
            <b-col cols="12" md="12" class="mb-1">
              <b-form-group
                label="Data do Job Sample"
                label-for="jobSampleDate"
              >
                <b-form-input
                  id="jobSampleDate"
                  v-model="jobSampleDate"
                  :class="{
                    'is-invalid': v$.jobSampleDate.$error,
                  }"
                  placeholder="24/03 e 26/03, às 19h"
                />
              </b-form-group>
            </b-col>
            <div class="sidebar-bottom px-1">
              <b-col cols="12" md="6" class="mt-2 sidebar-bottom-button">
                <b-button
                  variant="outline-primary"
                  @click="hide"
                  > Fechar
                </b-button>
              </b-col>
              <b-col cols="12" md="6" class="mt-2 sidebar-bottom-button">
                <b-button
                 :disabled="saving"
                  variant="primary"
                  type="submit"
                >  
                  {{ saving ? 'Salvando...' : 'Salvar' }}
                </b-button>
              </b-col>
            </div>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import * as types from '../store/types'
import FileChooser from '@/modules/shared/components/FileChooser.vue'

export default {
  components: {
    FileChooser,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      loading: false,
      jobSampleDate: undefined
    }
  },
  computed: {
    ...mapGetters({
      jobSampleSettingsSidebar: types.JOB_SAMPLE_SETTINGS_SIDEBAR
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      jobSampleDate: { required }
    }
  },
  methods: {
    ...mapMutations({
      mutateJobSampleSettingsSidebar: types.MUTATE_JOB_SAMPLE_SETTINGS_SIDEBAR
    }),
    ...mapActions({
      getActiveRecruitment: types.GET_ACTIVE_RECRUITMENT,
      updateJobSampleDate: types.UPDATE_JOB_SAMPLE_DATE
    }),
    onShow() {
      this.loading = true;
      this.getActiveRecruitment()
        .then(response => {
          this.jobSampleDate = response.data.job_sample_date
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clear(){
      this.jobSampleDate = undefined
      this.v$.$reset();
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.saving = true
      this.updateJobSampleDate({
          job_sample_date: this.jobSampleDate
      })
        .then(response => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "A data foi atualizada!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateJobSampleSettingsSidebar({visible: false})
          })
        .catch((err) => {
          if(err.response.status == 405){
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }else{
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar a data. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar-bottom {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sidebar-bottom-button {
    button {
      display: flex;
      border-radius: 10px;
      height: 38px;
      align-items: center;
      justify-content: center;
      width: 170px;
      font-size: 14px;
    }
  }
}
</style>
