<template>
  <div class="kanban-column">
    <div class="column-header d-flex align-items-center">
      <h5 :class="`badge-type bg-${cardTypeClass}`">
        {{ totalizer }}
      </h5>
      <h5>{{ stageName }}</h5>
    </div>
    <draggable
      v-model="draggableItems"
      group="recruitments"
      :class="`scrolled scroll-${skin} kanban-cards`"
    >
      <b-card
        v-for="(recruitment, index) in recruitments"
        :key="index"
        no-body
        :class="[
          `border-${cardTypeClass} kanban-card`,
          recruitment.is_overdue ? 'is-overdue-kanban' : '',
        ]"
      >
        <b-card-body class="d-flex flex-column">
          <!-- <div class="d-flex justify-content-between align-items-center">
            <div class="lead-qtd">
              <span :class="`badge-type bg-${cardTypeClass}`">{{
                recruitment.id
              }}</span>
              <span class="ml-1 text-white"
                ><b>{{ cardType }}</b></span
              >
            </div>
            <div class="lead-time text-white">
              <feather-icon icon="ClockIcon" size="16" />
              <span class="mr-2"> 5d 0h</span>
              <span class="percentage">23,5%</span>
            </div>
          </div> -->
          <aside :class="`card-info bg-card-${skin}`">
            <div
              class="card-type d-flex align-items-center justify-content-between mb-1"
            >
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  :checked="leadsSelected.includes(recruitment.customer_id)"
                  @change="toggleSelect(recruitment.customer_id)"
                >
                </b-form-checkbox>
                <!-- <span :class="`badge-type bg-${cardTypeClass}`">{{
                  cardType
                }}</span> -->
              </div>
              <div class="d-flex align-items-center">
                <button
                  @click="
                    toggleFavorite(
                      recruitment.is_favorite,
                      recruitment.customer_id
                    )
                  "
                >
                  <img
                    :src="favoriteIcon(recruitment.is_favorite)"
                    alt="star"
                  />
                </button>
                <b-dropdown
                  variant="link"
                  no-caret
                  right
                  toggle-class="toggle-padding"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    @click="goToLeadDetails(recruitment.customer_id)"
                    ><span class="align-middle ml-50"
                      >Ver perfil</span
                    ></b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="type < finalApprovalId"
                    @click="changeStage(recruitment.customer_id)"
                  >
                    <span class="align-middle ml-50"> Mudar etapa </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-else-if="type == finalApprovalId"
                    @click="
                      convertToPn(
                        recruitment.customer_id,
                        recruitment.approval_date
                      )
                    "
                  >
                    <span class="align-middle ml-50"> Converter para PN </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="recruitment.has_resume"
                    @click="downloadResume(recruitment.customer_id)"
                    ><span class="align-middle ml-50"
                      >Ver currículo</span
                    ></b-dropdown-item
                  >
                  <b-dropdown-item @click="editTags(recruitment.customer_id)"
                    ><span class="align-middle ml-50"
                      >Adicionar tag</span
                    ></b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="type <= finalApprovalId"
                    @click="resendEmail(recruitment.id)"
                  >
                    <span
                      class="align-middle d-flex text-left ml-50"
                      v-if="stageName === 'Leads'"
                    >
                      Enviar email de <br />
                      Análise Comportamental
                    </span>
                    <span class="align-middle ml-50" v-else>
                      Reenviar email
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="type <= finalApprovalId"
                    @click="
                      disqualify(recruitment.id, recruitment.customer.name)
                    "
                    ><span class="align-middle ml-50 text-danger"
                      >Desclassificar lead</span
                    ></b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>

            <div
              class="lead-info d-flex align-items-end justify-content-between mb-1"
            >
              <div class="lead d-flex flex-column">
                <span class="lead-name"
                  ><b>{{ recruitment.customer.name }}</b></span
                >
              </div>
            </div>

            <div
              class="lead-info d-flex align-items-end justify-content-between mb-1"
            >
              <div class="lead d-flex flex-column">
                <div
                  v-if="recruitment.customer.cell_phone"
                  class="d-flex align-items-center"
                >
                  <feather-icon icon="PhoneIcon" size="16" />
                  <span>{{
                    recruitment.customer.cell_phone | formatPhone
                  }}</span>
                </div>
                <div
                  v-if="recruitment.customer.address_state"
                  class="adress d-flex align-items-center"
                >
                  <feather-icon icon="MapPinIcon" size="16" />
                  <span :title="recruitment.customer.address_state">{{
                    recruitment.customer.address_state
                  }}</span>
                </div>
              </div>
              <div
                :id="`card-${recruitment.customer_id}`"
                :class="[
                  'time d-flex align-items-center',
                  recruitment.is_overdue ? 'text-danger' : '',
                ]"
              >
                <feather-icon icon="ClockIcon" size="16" />
                <span class="mr-2">
                  {{ recruitment.recruitment_stage_days }}</span
                >
              </div>
              <b-tooltip
                v-if="recruitment.is_overdue"
                triggers="hover"
                :target="`card-${recruitment.customer_id}`"
                :delay="{ show: 500, hide: 50 }"
              >
                <h4 class="text-danger font-weight-bold">
                  <feather-icon icon="AlertTriangleIcon" size="20" /> Atenção
                </h4>
                <p>
                  Prazo esgotado nesta etapa!<br />Atrasado por
                  {{ transformStageDays(recruitment.recruitment_stage_days) }}.
                </p>
              </b-tooltip>
            </div>

            <div class="lead-status d-flex flex-column mb-1">
              <small>Status atual:</small>
              <b-badge
                :variant="badgeVariant(recruitment.customer_status)"
                pill
              >
                <feather-icon
                  v-if="recruitment.customer_status === 'A classificar'"
                  icon="ClockIcon"
                  size="10"
                />
                <img
                  v-else
                  :src="badgeIcon(recruitment.customer_status)"
                  width="12"
                />
                {{ recruitment.customer_status }}
              </b-badge>
            </div>

            <div
              v-if="recruitment.customer.source"
              class="lead-source d-flex flex-column mb-1"
            >
              <small>Fonte:</small>
              <span>{{ recruitment.customer.source | leadOrigin }}</span>
            </div>

            <div
              class="lead-data d-flex justify-content-between align-items-center mb-1"
            >
              <div
                class="d-flex flex-column"
                v-if="recruitment.next_appointment_date"
              >
                <small>Data do próximo compromisso:</small>
                <span>{{ formatDate(recruitment.next_appointment_date) }}</span>
              </div>
              <div class="d-flex flex-column" v-if="stageName === 'Road Show'">
                <small>Questionário já foi preenchido?</small>
                <span>{{
                  recruitment.roadshow_completed ? "Sim" : "Não"
                }}</span>
              </div>
            </div>

            <div class="lead-tags d-flex mb-1">
              <b-badge
                pill
                variant="lead-tag"
                v-for="(tag, index) in recruitment.customer.customer_tags"
                :key="index"
              >
                <small>#{{ tag.name }}</small>
              </b-badge>
            </div>

            <div
              :class="`lead-icons d-flex justify-content-between align-items-center top-line-${skin}`"
            >
              <div class="contact-icons">
                <button
                  :id="`recruitment-kanban-whatsapp-${recruitment.id}`"
                  :class="`btn-icons whats-icon hover-${skin}`"
                  @click="openWhatsapp(recruitment.customer.cell_phone)"
                >
                  <img
                    class=""
                    src="~@/assets/images/svg/icn_whats.svg"
                    width="24"
                  />
                </button>
                <b-tooltip
                  :target="`recruitment-kanban-whatsapp-${recruitment.id}`"
                  placement="top"
                >
                  Enviar mensagem no WhatsApp
                </b-tooltip>
                <template v-if="stageName === 'Road Show'">
                  <button
                    :id="`recruitment-kanban-roadshow-${recruitment.id}`"
                    :class="`btn-icons hover-${skin}`"
                    @click="copyRoadshow(recruitment.customer_id)"
                  >
                    <feather-icon icon="CopyIcon" size="24" />
                  </button>
                  <b-tooltip
                    :target="`recruitment-kanban-roadshow-${recruitment.id}`"
                    placement="top"
                  >
                    Copiar link do Road Show
                  </b-tooltip>
                </template>
                <button
                  :id="`recruitment-kanban-comment-${recruitment.id}`"
                  :class="`btn-icons hover-${skin}`"
                  @click="addComment(recruitment.customer_id)"
                >
                  <feather-icon icon="MessageSquareIcon" size="24" />
                </button>
                <b-tooltip
                  :target="`recruitment-kanban-comment-${recruitment.id}`"
                  placement="top"
                >
                  Adicionar comentário
                </b-tooltip>
              </div>
              <div class="status-icons">
                <template v-for="(iconStatus, index) in statusIcons">
                  <button
                    :key="index"
                    :id="`recruitment-kanban-${iconStatus}-${recruitment.id}`"
                    :class="`btn-icons hover-${skin}`"
                    @click="toggleStatus(recruitment.customer_id, iconStatus)"
                  >
                    <img
                      :src="
                        getStatusIcon(iconStatus, recruitment.customer_status)
                      "
                      width="24"
                    />
                  </button>
                  <b-tooltip
                    :target="`recruitment-kanban-${iconStatus}-${recruitment.id}`"
                    placement="top"
                  >
                    {{ getStatusName(iconStatus) }}
                  </b-tooltip>
                </template>
              </div>
            </div>
          </aside>
        </b-card-body>
      </b-card>
      <div
        v-if="hasMore"
        class="load-more-container w-100 d-flex justify-content-center mt-1"
      >
        <b-button variant="primary" @click="loadMore(type)"
          >Carregar mais</b-button
        >
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent";
import { FINAL_APPROVAL } from "@/constants/recruitment_stages";

export default {
  components: {
    draggable,
  },
  props: {
    stageName: {
      type: String,
      required: true,
    },
    recruitments: {
      type: Array,
      required: true,
    },
    cardType: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    totalizer: {
      type: Number,
      required: true,
    },
    search: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      statusIcons: ["hot", "cold", "disinterested"],
      roadShowUrl: process.env.VUE_APP_ROADSHOW_FRONTEND_URL,
      finalApprovalId: FINAL_APPROVAL.id,
    };
  },
  computed: {
    ...mapGetters({
      leadsSelected: types.LEADS_SELECTED,
    }),
    cardTypeClass() {
      const typeMap = {
        1: "lead",
        2: "road-show",
        3: "group-dynamic",
        4: "individual-interview",
        5: "pre-job-sample",
        6: "job-sample",
        7: "final-approval",
        8: "converted-to-pn",
      };
      return typeMap[this.type] || "default";
    },
    hasMore() {
      return this.recruitments.length < this.totalizer;
    },
    draggableItems: {
      get() {
        return this.recruitments;
      },
      set(value) {
        if (value && value.length > 0) {
          if (
            value[0].recruitment_stage_id < this.type &&
            this.type <= this.finalApprovalId
          ) {
            this.moveRecruitmentLeadsStage({
              recruitment: value[0],
              stage: this.type,
            })
              .catch(() => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao alterar a etapa. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      moveRecruitmentLeadsStage: types.MOVE_RECRUITMENT_LEADS_STAGE,
      openLeadTagSidebar: types.OPEN_LEAD_TAG_SIDEBAR,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: sharedTypes.SAVE_CUSTOMER_COMMENT,
      getRoadshowToken: sharedTypes.GET_ROADSHOW_TOKEN,
      resendStageEmail: types.RESEND_STAGE_EMAIL,
    }),
    ...mapMutations({
      toggleLeadSelection: types.TOGGLE_LEAD_SELECTION,
      mutateModalRecruitmentStage: types.MUTATE_MODAL_RECRUITMENT_STAGE,
      moveRecruitment: types.MOVE_RECRUITMENT,
    }),
    transformStageDays(stageDays) {
      const daysMatch = stageDays.match(/(\d+)d/);
      const hoursMatch = stageDays.match(/(\d+)h/);
      const days = daysMatch ? `${daysMatch[1]} dias e` : "";
      const hours = hoursMatch ? `${hoursMatch[1]} horas` : "";
      return `${days} ${hours}`.trim();
    },
    openWhatsapp(cell_phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + cell_phone, "_blank").focus();
      });
    },
    goToLeadDetails(customer_id) {
      var routeData = this.$router.resolve({
        name: "lead-pn-profile",
        params: { id: customer_id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    badgeVariant(status) {
      const variantMap = {
        "A classificar": "undefined-user",
        Quente: "hot-user",
        Frio: "cold-user",
        Desinteressado: "disinterested-user",
        "Entrevista agendada": "scheduled_interview-user",
        "Entrevista feita": "done_interview-user",
        "Entrevista PN feita": "done_interview-lead-consultant",
        Suporte: "support-user",
        Básico: "basic_sub-user",
        Essencial: "essential_sub-user",
        Elite: "elite_sub-user",
        'Transformado em PN': "transformed-user",
        default: "default-user",
      };
      return variantMap[status] || "default-user";
    },
    badgeIcon(status) {
      const iconMap = {
        Quente: require("../../../assets/images/svg/icn_hot_active.svg"),
        Frio: require("../../../assets/images/svg/icn_cold_active.svg"),
        Desinteressado: require("../../../assets/images/svg/icn_disinterested_active.svg"),
        'Transformado em PN': require("../../../assets/images/svg/icn_converted_to_pn_active.svg"),
      };
      return iconMap[status] || "";
    },
    favoriteIcon(isFavorite) {
      return isFavorite
        ? require("../../../assets/images/svg/icn_star_active.svg")
        : require("../../../assets/images/svg/icn_star_inactive.svg");
    },
    getStatusIcon(iconStatus, customerStatus) {
      let statusLowerCase = customerStatus?.toLowerCase();
      if (statusLowerCase === "quente") {
        statusLowerCase = "hot";
      }
      if (statusLowerCase === "frio") {
        statusLowerCase = "cold";
      }
      if (statusLowerCase === "desinteressado") {
        statusLowerCase = "disinterested";
      }
      const suffix = statusLowerCase.includes(iconStatus)
        ? "active"
        : "disable";
      return require(`@/assets/images/svg/icn_${iconStatus}_${suffix}.svg`);
    },
    getStatusName(iconStatus) {
      const statusMap = {
        hot: "Esquentar",
        cold: "Esfriar",
        disinterested: "Desinteressado",
      };
      return statusMap[iconStatus] || "";
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString("pt-BR", options).replace(",", " às");
    },
    toggleFavorite(favorite, id) {
      this.$emit("toggle-favorite", favorite, id);
    },
    toggleStatus(status, id) {
      this.$emit("toggle-status", id, status);
    },
    loadMore(stageId) {
      this.$emit("load-more", stageId);
    },
    toggleSelect(customer_id) {
      this.toggleLeadSelection(customer_id);
    },
    changeStage(customer_id) {
      this.mutateModalRecruitmentStage({ customer_id, visible: true });
    },
    editTags(customer_id) {
      this.openLeadTagSidebar({
        customer_id,
        saveAction: () => {
          this.search();
        },
      });
    },
    downloadResume(customer_id) {
      this.$emit("download-resume", customer_id);
    },
    disqualify(id, customer_name) {
      this.$emit("disqualify", id, customer_name);
    },
    convertToPn(customer_id, approval_date) {
      this.$emit("convert-to-pn", customer_id, approval_date);
    },
    async saveLeadComment(payload) {
      return this.saveComment({
        customer_id: payload.id,
        comment: payload.comment,
      });
    },
    addComment(customer_id) {
      this.openCommentSidebar({
        id: customer_id,
        title: "Adicionar comentário",
        saveAction: this.saveLeadComment,
      });
    },
    async copyRoadshow(customer_id) {
      try {
        const response = await this.getRoadshowToken(customer_id);
        const roadShowUrl =
          this.roadShowUrl + "/autenticacao/" + response.data.access_token;
        await navigator.clipboard.writeText(roadShowUrl);
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "Link copiado para àrea de transferência!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      } catch ($e) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao copiar o link.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    resendEmail(id) {
      this.resendStageEmail(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "E-mail enviado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao enviar o e-mail. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.kanban-column {
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 355px;

  .column-header {
    h5 {
      inline-size: max-content;
    }
    h5:first-child {
      margin-right: 0.5rem;
    }
  }
}

.kanban-cards {
  flex-grow: 1;
  min-height: 100px;
  max-height: 700px;
  overflow: auto;
}

.scrolled {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f26237;
    border-radius: 20px;
  }
}

.scroll-dark {
  &::-webkit-scrollbar-track {
    background: #181d2f;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid #181d2f;
  }
}

.scroll-light {
  &::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid #f8f8f8;
  }
}

.card-header,
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .card-body {
  margin: 10px 0;
} */

.card {
  min-width: 340px;
  margin-bottom: 1rem;
  border-radius: 10px;
  border: 1px solid #636363;
}

.bg-lead {
  background-color: #3ba3f3;
}

.bg-road-show {
  background-color: #c62fc9;
}

.bg-group-dynamic {
  background-color: #f32f6d;
}

.bg-individual-interview {
  background-color: #f58240;
}

.bg-pre-job-sample {
  background-color: #775dd0;
}

.bg-job-sample {
  background-color: #546e7a;
}

.bg-final-approval {
  background-color: #26a69a;
}

.bg-converted-to-pn {
  background-color: #d10ce8;
}

.border-lead {
  border-left: 5px solid #3ba3f3;
}

.border-road-show {
  border-left: 5px solid #c62fc9;
}

.border-group-dynamic {
  border-left: 5px solid #f32f6d;
}

.border-individual-interview {
  border-left: 5px solid #f58240;
}

.border-pre-job-sample {
  border-left: 5px solid #775dd0;
}

.border-job-sample {
  border-left: 5px solid #546e7a;
}

.border-final-approval {
  border-left: 5px solid #26a69a;
}

.border-converted-to-pn {
  border-left: 5px solid #d10ce8;
}

.badge-type {
  width: min-content;
  padding: 3px 8px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

.lead-time {
  .percentage {
    font-size: 0.85rem;
  }
}

.lead-tags {
  width: 100%;
  flex-wrap: wrap;
  span {
    margin: 0.5rem 0.5rem 0 0;
  }
}

.is-overdue-kanban {
  border-top: 2px solid #e45455;
  border-right: 2px solid #e45455;
  border-bottom: 2px solid #e45455;
}

// .bg-card-dark {
//   background-color: #161d31;
// }

// .bg-card-light {
//   background-color: #f8f8f8;
// }

.card-info {
  border-radius: 0.428rem;

  .card-type {
    .custom-checkbox {
      margin-right: 0.5rem;
    }

    button {
      background: none;
      border: none;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.lead-info {
  .lead,
  .time {
    svg {
      margin-right: 0.5rem;
    }
  }

  .lead {
    .lead-name {
      font-size: 1.25rem !important;
    }
  }

  .adress {
    span {
      max-width: 250px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.lead-status {
  small {
    margin-bottom: 0.5rem;
  }

  .badge {
    width: min-content;
  }
}

.lead-source {
  small {
    margin-bottom: 0.25rem;
  }
}

.top-line-dark {
  width: 100%;
  border-top: 1px solid #636363;
}

.top-line-light {
  width: 100%;
  border-top: 1px solid #f8f8f8;
}

.lead-icons {
  padding-top: 1rem;

  button {
    background: none;
    border: none;
    transition: all 0.3s ease;
    padding: 0.5rem;

    .feather {
      filter: brightness(0) saturate(100%) invert(81%) sepia(10%) saturate(136%)
        hue-rotate(182deg) brightness(89%) contrast(91%);
    }

    img,
    .feather {
      height: 24px;
    }
  }

  .whats-icon {
    img {
      filter: brightness(0) saturate(100%) invert(51%) sepia(70%)
        saturate(1070%) hue-rotate(81deg) brightness(107%) contrast(118%);
    }

    &:hover {
      background-color: rgba(13, 128, 29, 0.3) !important;
    }
  }

  .hover-dark:hover {
    background-color: rgba(248, 248, 248, 0.1);
    border-radius: 0.428rem;
  }

  .hover-light:hover {
    background-color: rgba(22, 29, 49, 0.1);
    border-radius: 0.428rem;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.toggle-padding {
  padding: 0.786rem !important;
  padding-right: 0 !important;
}
</style>
