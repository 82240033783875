<template>
  <div class="mt-1">
    <b-card no-body>
      <b-card-header
        class="pb-50 position-relative d-flex justify-content-end align-items-center"
        @click="visible = !visible"
      >
        <div class="kanban-column">
          <div class="column-header d-flex align-items-center">
            <div class="donut-chart-container">
              <vue-apex-charts
                type="donut"
                width="100"
                height="100"
                :options="chartOptions"
                :series="stagePercentage"
              />
            </div>
            <h5 :class="`badge-type bg-${cardTypeClass}`">
              {{ totalizer }}
            </h5>
            <h5>{{ stageName }}</h5>
          </div>
        </div>

        <b-button class="btn-icon" variant="flat" style="padding: 0">
          <div class="d-flex align-items-center mb-1">
            <feather-icon v-if="!visible" size="20" icon="ChevronDownIcon" />
            <feather-icon v-else size="20" icon="ChevronUpIcon" />
          </div>
        </b-button>
      </b-card-header>
      <b-table
        v-if="visible"
        hover
        class="position-relative text-center lead-table"
        :items="processedRecruitments"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados leads para esta busca"
        :busy="isLoading"
        :primary-key="'customer.customer_id'"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
        :tbody-tr-class="rowClass"
      >
        <template #cell(index)="data">
          <div class="d-flex align-items-center status-icons">
            <b-form-checkbox
              :checked="leadsSelected.includes(data.item.customer.id)"
              @change="toggleSelect(data.item.customer.id)"
            />
            <button
              class="btn-icon ml-2"
              @click="
                toggleFavorite(data.item.is_favorite, data.item.customer.id)
              "
            >
              <img
                class="star-icon"
                :src="
                  data.item.is_favorite
                    ? require('@/assets/images/svg/icn_star_active.svg')
                    : require('@/assets/images/svg/icn_star_inactive.svg')
                "
              />
            </button>
          </div>
        </template>

        <template #cell(name)="data">
          <div
            class="name-content"
            @click="goToLeadDetails(data.item.customer.id)"
          >
            <b-badge variant="lead-name" class="name">
              <div class="text-capitalize text-wrap">
                {{ data.item.customer.name }}
              </div>
              <div v-if="data.item.customer.cell_phone" class="phone">
                {{ data.item.customer.cell_phone | formatPhone }}
              </div>
            </b-badge>
          </div>
        </template>

        <template #cell(address_state)="data">
          <div v-if="data.item.address_state">
            <div>{{ data.item.address_state }}</div>
            <div v-if="data.item.address_state" class="text-muted">
              {{ data.item.address_state }}
            </div>
          </div>
          <div v-else>N/A</div>
        </template>

        <template #cell(recruitment_stage_days)="data">
          <span
            v-if="data.item.recruitment_stage_days"
            :id="`days-${data.item.customer_id}`"
            :class="
              data.item.is_overdue
                ? 'text-danger font-weight-bold d-block-max'
                : 'd-block-max'
            "
          >
            <feather-icon
              v-if="data.item.is_overdue"
              icon="AlertTriangleIcon"
              size="20"
            />
            {{ transformStageDays(data.item.recruitment_stage_days) }}
          </span>

          <span v-else>N/A</span>
          <b-tooltip
            v-if="data.item.is_overdue"
            triggers="hover"
            :target="`days-${data.item.customer_id}`"
            :delay="{ show: 500, hide: 50 }"
          >
            <h4 class="text-danger font-weight-bold">
              <feather-icon icon="AlertTriangleIcon" size="20" /> Atenção
            </h4>
            <p>
              Prazo esgotado nesta etapa!<br />Atrasado por
              {{ transformStageDays(data.item.recruitment_stage_days) }}.
            </p>
          </b-tooltip>
        </template>

        <template #cell(source)="data">
          <span v-if="data.item.customer.source">
            {{ data.item.customer.source | leadOrigin  }}
          </span>
          <div v-else>N/A</div>
        </template>

        <template #cell(tags)="data">
          <span class="d-block-max" :id="`tags-${data.item.customer_id}`">
            {{ data.item.customer.customer_tags.length }}
            {{ data.item.customer.customer_tags.length <= 1 ? "tag" : "tags" }}
          </span>

          <b-tooltip
            v-if="data.item.customer.customer_tags.length"
            triggers="hover"
            :target="`tags-${data.item.customer_id}`"
            :delay="{ show: 500, hide: 50 }"
          >
            <h5>
              <b>TAGs</b> <feather-icon icon="PlusCircleIcon" size="16" />
            </h5>

            <div class="lead-tags d-flex mb-1">
              <b-badge
                pill
                variant="lead-tag"
                class="mr-1"
                v-for="(tag, index) in data.item.customer.customer_tags"
                :key="index"
              >
                <small>#{{ tag.name }}</small>
              </b-badge>
            </div>
          </b-tooltip>
        </template>

        <template #cell(status)="data">
          <div class="d-flex flex-column align-items-center">
            <b-badge :variant="badgeVariant(data.item.customer_status)" pill>
              <img
                v-if="data.item.customer_status !== 'A classificar'"
                :src="badgeIcon(data.item.customer_status)"
                width="12"
              />
              {{ data.item.customer_status }}
            </b-badge>

            <div class="status-icons d-flex">
              <template v-for="(iconStatus, index) in statusIcons">
                <button
                  :key="index"
                  :id="`recruitment-kanban-${iconStatus}-${data.item.customer.id}`"
                  :class="`btn-icons hover-${skin}`"
                  @click="toggleStatus(data.item.customer.id, iconStatus)"
                >
                  <img
                    :src="getStatusIcon(iconStatus, data.item.customer_status)"
                    width="24"
                  />
                </button>
                <b-tooltip
                  :target="`recruitment-kanban-${iconStatus}-${data.item.customer.id}`"
                  placement="top"
                >
                  {{ getStatusName(iconStatus) }}
                </b-tooltip>
              </template>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <div class="lead-icons d-flex">
              <button
                :id="`recruitment-kanban-whatsapp-${data.item.customer.id}`"
                :class="`btn-icons whats-icon hover-${skin}`"
                @click="openWhatsapp(data.item.customer.cell_phone)"
              >
                <img
                  class=""
                  src="~@/assets/images/svg/icn_whats.svg"
                  width="24"
                />
              </button>
              <b-tooltip
                :target="`recruitment-kanban-whatsapp-${data.item.customer.id}`"
                placement="top"
              >
                Enviar mensagem no WhatsApp
              </b-tooltip>
              <template v-if="stageName === 'Road Show'">
                <button
                  :id="`recruitment-kanban-roadshow-${data.item.customer.id}`"
                  :class="`btn-icons hover-${skin}`"
                  @click="copyRoadshow(data.item.customer.id)"
                >
                  <feather-icon icon="CopyIcon" size="24" />
                </button>
                <b-tooltip
                  :target="`recruitment-kanban-roadshow-${data.item.customer.id}`"
                  placement="top"
                >
                  Copiar link do Road Show
                </b-tooltip>
              </template>
              <button
                :id="`recruitment-kanban-comment-${data.item.customer.id}`"
                :class="`btn-icons hover-${skin}`"
                @click="addComment(data.item.customer.id)"
              >
                <feather-icon icon="MessageSquareIcon" size="24" />
              </button>
              <b-tooltip
                :target="`recruitment-kanban-comment-${data.item.customer.id}`"
                placement="top"
              >
                Adicionar comentário
              </b-tooltip>
            </div>

            <b-dropdown variant="link" no-caret right boundary="window">
              <template #button-content>
                <feather-icon
                  class="text-body"
                  icon="MoreVerticalIcon"
                  size="20"
                />
              </template>
              <b-dropdown-item @click="goToLeadDetails(data.item.customer.id)"
                ><span class="align-middle ml-50"
                  >Ver perfil</span
                ></b-dropdown-item
              >
              <b-dropdown-item
                v-if="type < finalApprovalId"
                @click="changeStage(data.item.customer.id)"
              >
                <span class="align-middle ml-50"> Mudar etapa </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="type == finalApprovalId"
                @click="
                  convertToPn(data.item.customer.id, data.item.approval_date)
                "
              >
                <span class="align-middle ml-50"> Converter para PN </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.has_resume"
                @click="downloadResume(data.item.customer.id)"
                ><span class="align-middle ml-50"
                  >Ver currículo</span
                ></b-dropdown-item
              >
              <b-dropdown-item @click="editTags(data.item.customer.id)"
                ><span class="align-middle ml-50"
                  >Adicionar tag</span
                ></b-dropdown-item
              >
              <b-dropdown-item
                v-if="type <= finalApprovalId"
                @click="resendEmail(data.item.id)"
              >
                <span
                  class="align-middle d-flex text-left ml-50"
                  v-if="stageName === 'Leads'"
                >
                  Enviar email de <br />
                  Análise Comportamental
                </span>
                <span class="align-middle ml-50" v-else> Reenviar email </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="type <= finalApprovalId"
                @click="disqualify(data.item.id, data.item.customer.name)"
                ><span class="align-middle ml-50 text-danger"
                  >Desclassificar lead</span
                ></b-dropdown-item
              >
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2" v-if="visible">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { useToast } from "vue-toastification/composition";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import VueApexCharts from "vue-apexcharts";
import * as sharedTypes from "@/modules/shared/store/types";
import { FINAL_APPROVAL } from "@/constants/recruitment_stages";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    stageName: {
      type: String,
      required: true,
    },
    recruitments: {
      type: Array,
      required: true,
    },
    selectedPage: {
      type: Number,
      default: 1,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    totalizer: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    stagePercentage: {
      type: Array,
      default: () => [0, 100],
      validator: (value) => {
        return value.length === 2 && !value.some(isNaN);
      },
    },
  },
  setup() {
    const toast = useToast();
    const { skin } = useAppConfig();
    return { toast, skin };
  },
  data() {
    return {
      sortField: undefined,
      currentPage: this.selectedPage,
      visible: this.stageName === "Leads",
      statusIcons: ["hot", "cold", "disinterested"],
      roadShowUrl: process.env.VUE_APP_ROADSHOW_FRONTEND_URL,
      finalApprovalId: FINAL_APPROVAL.id,
    };
  },
  computed: {
    ...mapGetters({
      tableColumns: types.RECRUITMENT_TABLE_COLUMNS,
      leadsSelected: types.LEADS_SELECTED,
    }),
    cardTypeClass() {
      const typeMap = {
        1: "lead",
        2: "road-show",
        3: "group-dynamic",
        4: "individual-interview",
        5: "pre-job-sample",
        6: "job-sample",
        7: "final-approval",
        8: "converted-to-pn",
      };
      return typeMap[this.type] || "default";
    },
    colorMap() {
      return {
        lead: "#3ba3f3",
        "road-show": "#c62fc9",
        "group-dynamic": "#f32f6d",
        "individual-interview": "#f58240",
        "pre-job-sample": "#775dd0",
        "job-sample": "#546e7a",
        "final-approval": "#26a69a",
        "converted-to-pn": "#d10ce8",
      };
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        labels: ["Stage", "Remaining"],
        colors: [
          this.colorMap[this.cardTypeClass],
          this.skin === "dark" ? "#40465a" : "#f6f6f6",
        ],
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  color: this.skin === "dark" ? "#fff" : "#6e6b7b",
                  fontSize: "11px",
                  fontWeight: "bold",
                  offsetY: 5,
                },
                total: {
                  show: true,
                  formatter: (w) => {
                    const percentage = w.globals.seriesTotals[0];
                    return `${percentage.toFixed(2)}%`;
                  },
                },
              },
            },
          },
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      };
    },
    processedRecruitments() {
      return this.recruitments.map((item, index) => ({
        ...item,
        index: index + 1 + (this.currentPage - 1) * this.perPage,
      }));
    },
    pageItemsFrom() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    pageItemsTo() {
      return Math.min(this.currentPage * this.perPage, this.totalItems);
    }
  },
  watch: {
    selectedPage(value) {
      if (value !== this.currentPage) {
        this.currentPage = value;
      }
    },
  },
  methods: {
    ...mapActions({
      getRoadshowToken: sharedTypes.GET_ROADSHOW_TOKEN,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: sharedTypes.SAVE_CUSTOMER_COMMENT,
      resendStageEmail: types.RESEND_STAGE_EMAIL,
      openLeadTagSidebar: types.OPEN_LEAD_TAG_SIDEBAR,
    }),
    ...mapMutations({
      toggleLeadSelection: types.TOGGLE_LEAD_SELECTION,
      mutateModalRecruitmentStage: types.MUTATE_MODAL_RECRUITMENT_STAGE,
    }),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item?.is_overdue) return "is-overdue-border";
    },

    getStatusIcon(iconStatus, customerStatus) {
      let statusLowerCase = customerStatus?.toLowerCase();
      if (statusLowerCase === "quente") {
        statusLowerCase = "hot";
      }
      if (statusLowerCase === "frio") {
        statusLowerCase = "cold";
      }
      if (statusLowerCase === "desinteressado") {
        statusLowerCase = "disinterested";
      }
      const suffix = statusLowerCase.includes(iconStatus)
        ? "active"
        : "disable";
      return require(`@/assets/images/svg/icn_${iconStatus}_${suffix}.svg`);
    },
    getStatusName(iconStatus) {
      const statusMap = {
        hot: "Esquentar",
        cold: "Esfriar",
        disinterested: "Desinteressado",
      };
      return statusMap[iconStatus] || "";
    },

    transformStageDays(stageDays) {
      const daysMatch = stageDays.match(/(\d+)d/);
      const hoursMatch = stageDays.match(/(\d+)h/);
      const days = daysMatch ? `${daysMatch[1]} dias e` : "";
      const hours = hoursMatch ? `${hoursMatch[1]} horas` : "";
      return `${days} ${hours}`.trim();
    },
    goToLeadDetails(customer_id) {
      this.$router.push({ name: "lead-profile", params: { id: customer_id } });
    },
    openWhatsapp(cell_phone) {
      window.open(`https://wa.me/${cell_phone}`, "_blank");
    },
    toggleFavorite(favorite, customer_id) {
      this.$emit("toggle-favorite", favorite, customer_id);
    },
    toggleSelect(customer_id) {
      this.$emit("toggle-select", customer_id);
    },
    toggleStatus(status, id) {
      this.$emit("toggle-status", id, status);
    },
    badgeVariant(status) {
      const variantMap = {
        "A classificar": "undefined-user",
        Quente: "hot-user",
        Frio: "cold-user",
        Desinteressado: "disinterested-user",
      };
      return variantMap[status] || "default-user";
    },
    badgeIcon(status) {
      const iconMap = {
        Quente: require("@/assets/images/svg/icn_hot_active.svg"),
        Frio: require("@/assets/images/svg/icn_cold_active.svg"),
        Desinteressado: require("@/assets/images/svg/icn_disinterested_active.svg"),
      };
      return iconMap[status] || "";
    },
    onPageChange(page) {
      this.currentPage = page;
      this.$emit("page-change", page);
    },
    sort(event) {
      this.sortField = event.sortBy;
      this.$emit("sort", event);
    },

    toggleSelect(customer_id) {
      this.toggleLeadSelection(customer_id);
    },
    changeStage(customer_id) {
      this.mutateModalRecruitmentStage({ customer_id, visible: true });
    },
    editTags(customer_id) {
      this.openLeadTagSidebar({
        customer_id,
        saveAction: () => {
          this.search();
        },
      });
    },
    downloadResume(customer_id) {
      this.$emit("download-resume", customer_id);
    },
    disqualify(id, customer_name) {
      this.$emit("disqualify", id, customer_name);
    },
    convertToPn(customer_id, approval_date) {
      this.$emit("convert-to-pn", customer_id, approval_date);
    },
    async saveLeadComment(payload) {
      return this.saveComment({
        customer_id: payload.id,
        comment: payload.comment,
      });
    },
    addComment(customer_id) {
      this.openCommentSidebar({
        id: customer_id,
        title: "Adicionar comentário",
        saveAction: this.saveLeadComment,
      });
    },
    async copyRoadshow(customer_id) {
      try {
        const response = await this.getRoadshowToken(customer_id);
        const roadShowUrl =
          this.roadShowUrl + "/autenticacao/" + response.data.access_token;
        await navigator.clipboard.writeText(roadShowUrl);
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "Link copiado para àrea de transferência!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      } catch ($e) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao copiar o link.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    resendEmail(id) {
      this.resendStageEmail(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "E-mail enviado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao enviar o e-mail. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.d-block-max {
  display: block;
  width: max-content;
}

.badge-type {
  width: min-content;
  padding: 3px 8px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.card-header {
  padding: 5rem 0;
  cursor: pointer;
}

.kanban-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  position: absolute;
  top: -5px;
  left: 0;

  .column-header {
    h5:first-child {
      margin-right: 0.5rem;
    }
  }
}

.btn-icon {
  position: relative;

  svg {
    position: absolute;
    right: 30px;
    top: -30px;
  }
}

.status-icons {
  button {
    background: none;
    border: none;
    transition: all 0.3s ease;
    padding: 0.5rem;

    img {
      width: 18px;
      height: 18px;
    }

    .star-icon {
      width: 25px;
      height: 25px;
    }
  }
}

.lead-icons {
  button {
    background: none;
    border: none;
    transition: all 0.3s ease;
    padding: 0.5rem;

    .feather {
      filter: brightness(0) saturate(100%) invert(81%) sepia(10%) saturate(136%)
        hue-rotate(182deg) brightness(89%) contrast(91%);
    }

    img,
    .feather {
      height: 20px;
    }
  }

  .whats-icon {
    img {
      filter: brightness(0) saturate(100%) invert(51%) sepia(70%)
        saturate(1070%) hue-rotate(81deg) brightness(107%) contrast(118%);
    }

    &:hover {
      background-color: rgba(13, 128, 29, 0.3) !important;
    }
  }

  .hover-dark:hover {
    background-color: rgba(248, 248, 248, 0.1);
    border-radius: 0.428rem;
  }

  .hover-light:hover {
    background-color: rgba(22, 29, 49, 0.1);
    border-radius: 0.428rem;
  }
}

.bg-lead {
  background-color: #3ba3f3;
}

.bg-road-show {
  background-color: #c62fc9;
}

.bg-group-dynamic {
  background-color: #f32f6d;
}

.bg-individual-interview {
  background-color: #f58240;
}

.bg-pre-job-sample {
  background-color: #775dd0;
}

.bg-job-sample {
  background-color: #546e7a;
}

.bg-final-approval {
  background-color: #26a69a;
}

.bg-converted-to-pn {
  background-color: #d10ce8;
}

.per-page-selector {
  width: 90px;
}

.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}

input[type="datetime-local"] {
  width: 180px;
}

.lead-table .phone {
  color: #e3e3e3;
}

.dark-layout .lead-table .phone {
  color: #676d7d;
}

.lead-table .name-content {
  width: 170px;
  display: flex;
  justify-content: center;
}

.lead-table .name-content .name {
  width: auto;
}

.lead-table .name-content .name:hover {
  background-color: #f26237;
}

.lead-table .name-content .whats {
  width: 27px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.lead-table .name-content .whats:hover {
  background-color: #f26237;
}

.lead-table .btn-group .btn {
  border: unset !important;
}

@media (max-width: 768px) {
  .search-buttons-section {
    flex-direction: column-reverse;
  }

  .search-button {
    width: 100%;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

table #buttons {
  width: 40px;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.lead-table thead th {
  white-space: nowrap !important;
}

.is-overdue-border {
  border-left: 5px solid #e45455;
}
</style>
