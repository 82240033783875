<template>
  <div>
    <b-sidebar
      id="sidebar-import-leads"
      sidebar-class="sidebar-lg"
      :visible="importLeadsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateImportLeadsSidebar({ visible: val })"
      @hidden="clear"
      @shown="onShow"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Importação de leads</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="12" class="mb-1">
              <b-form-group
                label="Nome da importação"
                label-for="import-name"
              >
                <b-form-input
                  id="import-name"
                  v-model="name"
                  :class="{ 'is-invalid': v$.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.name.required.$invalid">
                    Você deve informar um nome para a importação
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" class="mb-1">
              <b-form-group 
                label="Responsável pela importação" 
                label-for="import-consultant-owner"
              >
                <v-select
                  id="import-consultant-owner"
                  v-model="owner"
                  :options="consultants"
                  :reduce="(consultant) => consultant.id"
                  :loading="loading.consultants"
                  label="name"
                  :class="getSelectErrorClass(v$.owner.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.owner.required.$invalid">
                    Você deve informar um responsável pela importação
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" class="mb-1">
              <a :href="imporLeadSheetUrl">
                <feather-icon icon="DownloadIcon" size="16" /> Baixar modelo de planilha
              </a>
            </b-col>
            <b-col cols="12" md="12" class="mb-1">
              <b-form-group>
                <file-chooser
                  id="import-consultant-file"
                  label="Enviar planilha (apenas CSV)"
                  v-model="file"
                  :disabled="saving"
                  :thereIsError="v$.file.$error && v$.file.$invalid"
                  errorMessage="O envio de arquivos não pode ser maior que 10 MB."
                  onlySheets
                />
              </b-form-group>
            </b-col>
            <div class="sidebar-bottom px-1">
              <b-col cols="12" md="6" class="mt-2 sidebar-bottom-button">
                <b-button
                  variant="outline-primary"
                  @click="hide"
                  > Fechar
                </b-button>
              </b-col>
              <b-col cols="12" md="6" class="mt-2 sidebar-bottom-button">
                <b-button
                 :disabled="saving"
                  variant="primary"
                  type="submit"
                >  
                  {{ saving ? 'Importando...' : 'Importar' }}
                </b-button>
              </b-col>
            </div>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import * as accountTypes from "@/modules/account/store/types";
import FileChooser from '@/modules/shared/components/FileChooser.vue'
import maxFileSize from "../../../validators/file"

export default {
  components: {
    FileChooser,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: {
        consultants: false
      },
      imporLeadSheetUrl: process.env.VUE_APP_LEADS_PN_IMPORT_SHEET,
      saving: false,
      name: undefined,
      owner: undefined,
      file: null,
    }
  },
  computed: {
    ...mapGetters({
      importLeadsSidebar: types.IMPORT_LEADS_SIDEBAR,
      consultants: sharedTypes.CONSULTANTS,
      user: accountTypes.USER
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      name: { required },
      owner: { required },
      file: { 
        required,
        valid(file) {
          return !file || maxFileSize(file.size)
        }
      }
    }
  },
  mounted() {
    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
  },
  methods: {
    ...mapMutations({
      mutateImportLeadsSidebar: types.MUTATE_IMPORT_LEADS_SIDEBAR
    }),
    ...mapActions({
      importLeads: types.IMPORT_LEADS,
      getConsultants: sharedTypes.GET_CONSULTANTS,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    onShow() {
      this.owner = this.user.appify_consultant_id
    },
    clear(){
      this.name = undefined
      this.owner = undefined
      this.file = null
      this.v$.$reset();
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      const payload = {
        name: this.name,
        owner: this.owner,
        file: this.file
      }
      this.saving = true
      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value && value !== undefined && value !== "" && value !== "null") {
          request.append(key, value);
        }
      }
      this.importLeads(request)
        .then(response => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "A importação foi realizada com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateImportLeadsSidebar({visible: false})
            this.importLeadsSidebar.saveAction()
          })
        .catch((err) => {
          if(err.response.status == 405){
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }else{
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao realizar a importação. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar-bottom {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sidebar-bottom-button {
    button {
      display: flex;
      border-radius: 10px;
      height: 38px;
      align-items: center;
      justify-content: center;
      width: 170px;
      font-size: 14px;
    }
  }
}
</style>
