import { render, staticRenderFns } from "./ColumnRecruitmentList.vue?vue&type=template&id=fc87432a&scoped=true"
import script from "./ColumnRecruitmentList.vue?vue&type=script&lang=js"
export * from "./ColumnRecruitmentList.vue?vue&type=script&lang=js"
import style0 from "./ColumnRecruitmentList.vue?vue&type=style&index=0&id=fc87432a&prod&lang=scss&scoped=true"
import style1 from "./ColumnRecruitmentList.vue?vue&type=style&index=1&id=fc87432a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc87432a",
  null
  
)

export default component.exports