<template>
  <div>
    <LeadTagSidebar />
    <DisqualifyLeadSidebar />
    <CommentSidebar />
    <SettingsTableKanbanSidebar
      :searchKanban="fetchKanbanData"
      :searchTable="fetchTableData"
      :view="view"
    />
    <SaveConsultantBasicInformationSidebar />
    <b-row>
      <b-col cols="12" md="8" class="mb-1">
        <b-card no-body class="h-100 height-card overflow-hidden">
          <b-card-body class="d-flex flex-column pb-0">
            <div
              class="d-flex justify-content-between align-items-center w-100 mb-1"
            >
              <h5 class="mb-0">Balanço de recrutamento</h5>
              <b-form-group class="mb-0" style="min-width: 220px">
                <flat-pickr
                  id="searchCreatedAt"
                  class="form-control"
                  :config="flatPickrConfig"
                  :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
                  :value="createdAt"
                  @input="handleDateChange"
                />
              </b-form-group>
            </div>
            <ChartRecruitmentStatement :statement="statement" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mb-1">
        <b-card no-body class="h-100 height-card">
          <b-card-body class="d-flex flex-column pb-0">
            <LeadDetails 
              ref="leadDetails"
              :fetchLeadData="fetchLeadData"
              :loading="loadingLeadDetails"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <RecruitmentFilters
          ref="filters"
          :fetchData="fetchData"
          :loadingSearch="loading"
          :view="view"
        />
      </b-col>
      <b-col cols="12">
        <div class="tabs">
          <div
            :class="[
              'tabs-group',
              skin === 'dark' ? 'tabs-group-dark' : 'tabs-group-light',
            ]"
          >
            <button
              class="tab-button"
              :class="{ active: view === 'kanban' }"
              @click="setView('kanban')"
            >
              <feather-icon icon="ColumnsIcon" size="16" />
              Kanban
            </button>
            <button
              class="tab-button"
              :class="{ active: view === 'tabela' }"
              @click="setView('tabela')"
            >
              <feather-icon icon="TableIcon" size="16" />
              Tabela
            </button>
          </div>
        </div>
      </b-col>

      <b-col cols="12">
        <template v-if="view === 'kanban'">
          <b-col cols="12" v-if="loading">
            <div class="d-flex justify-content-center m-2">
              <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
            </div>
          </b-col>
          <b-col cols="12 px-0" v-else-if="filteredKanbanStages.length > 0">
            <div class="kanban-board">
              <ColumnRecruitmentList
                v-for="(stage, index) in filteredKanbanStages"
                :key="index"
                :stageName="stage.stage_name"
                :recruitments="stage.recruitments"
                :cardType="stage.stage_name"
                :type="stage.stage_id"
                :totalizer="stage.total"
                :search="fetchKanbanData"
                @toggle-favorite="toggleFavorite"
                @toggle-status="toggleStatus"
                @load-more="increaseLimit"
                @download-resume="resumeDownload"
                @disqualify="disqualifyLead"
                @convert-to-pn="convertToPn"
              />
            </div>
          </b-col>
          <b-col cols="12" v-else>
            <div
              class="d-flex justify-content-center m-2"
              style="font-size: 18px"
            >
              Nenhum resultado encontrado.
            </div>
          </b-col>
        </template>

        <template v-else>
          <b-col cols="12" v-if="loading">
            <div class="d-flex justify-content-center m-2">
              <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
            </div>
          </b-col>
          <b-col cols="12 px-0" v-else-if="filteredTableStages.length > 0">
            <div class="kanban-board flex-column">
              <TableRecruitmentList
                v-for="(stage, index) in sortedTableStages"
                :key="`${stage.stage_id}-${index}`"
                :stageName="stage.stage_name"
                :recruitments="stage.data"
                :totalizer="stage.total"
                :loading="loading"
                :totalItems="stage.total"
                :stagePercentage="stagePercentage(stage)"
                :perPage="stage.per_page"
                :type="stage.stage_id"
                :selectedPage="stage.current_page"
                stageID="221"
                @page-change="handlePageChange(stage.stage_id, $event)"
                @refresh="fetchTableData"
                @toggle-favorite="toggleFavorite"
                @toggle-select="toggleSelect"
                @toggle-status="toggleStatus"
                @convert-to-pn="convertToPn"
              />
            </div>
          </b-col>
          <b-col cols="12" v-else>
            <div
              class="d-flex justify-content-center m-2"
              style="font-size: 18px"
            >
              Nenhum resultado encontrado.
            </div>
          </b-col>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import ChartRecruitmentStatement from "../components/ChartRecruitmentStatement.vue";
import SettingsTableKanbanSidebar from "../components/SettingsTableKanbanSidebar.vue";
import LeadDetails from "../components/LeadDetails.vue";
import LeadTagSidebar from "../components/LeadTagSidebar.vue";
import ColumnRecruitmentList from "../components/ColumnRecruitmentList.vue";
import TableRecruitmentList from "../components/TableRecruitmentList.vue";
import DisqualifyLeadSidebar from "../components/DisqualifyLeadSidebar.vue";
import RecruitmentFilters from "../components/RecruitmentFilters.vue";
import CommentSidebar from "@/modules/shared/components/CommentSidebar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as types from "../store/types";
import * as statusTypes from "../../lead/store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";
import * as customerStatus from "@/constants/customers_status";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getRangeDates } from "@/helpers/date_picker";
import SaveConsultantBasicInformationSidebar from "@/modules/consultant/components/SaveConsultantBasicInformationSidebar.vue";
import { OPEN_NEW_CONSULTANT_SIDEBAR } from "@/modules/consultant/store/types";
import { STAGE_ORDER } from "@/constants/recruitment_stages";

export default {
  components: {
    ChartRecruitmentStatement,
    LeadDetails,
    LeadTagSidebar,
    ColumnRecruitmentList,
    DisqualifyLeadSidebar,
    RecruitmentFilters,
    TableRecruitmentList,
    flatPickr,
    CommentSidebar,
    SettingsTableKanbanSidebar,
    SaveConsultantBasicInformationSidebar,
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear();

    return { toast: useToast(), skin, actualYear };
  },

  data() {
    return {
      statement: [],
      loadingStatement: false,
      loading: false,
      createdAt: null,
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      limits: [
        { stage_id: 1, limit: 5 },
        { stage_id: 2, limit: 5 },
        { stage_id: 3, limit: 5 },
        { stage_id: 4, limit: 5 },
        { stage_id: 5, limit: 5 },
        { stage_id: 6, limit: 5 },
        { stage_id: 7, limit: 5 },
        { stage_id: 8, limit: 5 },
      ],
      view: "kanban",
      order: undefined,
      loadingLeadDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      kanbanStages: types.RECRUTMENT_KANBAN,
      settingsTableKanban: types.SETTINGS_TABLE_KANBAN,
      tableStages: types.RECRUTMENT_TABLE,
    }),

    filteredKanbanStages() {
      if (!Array.isArray(this.kanbanStages) || !this.settingsTableKanban)
        return [];

      return this.kanbanStages.filter((stage) => {
        const stageKey = this.getStageKey(stage.stage_name);
        return this.settingsTableKanban[stageKey] !== false;
      });
    },

    filteredTableStages() {
      if (!Array.isArray(this.tableStages)) return [];

      const uniqueStages = this.tableStages.reduce((acc, stage) => {
        if (!acc.find((s) => s.stage_id === stage.stage_id)) {
          acc.push(stage);
        }
        return acc;
      }, []);

      return uniqueStages;
    },

    sortedTableStages() {
      const stageOrder = STAGE_ORDER;

      if (!Array.isArray(this.filteredTableStages)) return [];

      const sorted = this.filteredTableStages.slice().sort((a, b) => {
        const indexA = stageOrder.indexOf(a.stage_name);
        const indexB = stageOrder.indexOf(b.stage_name);

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });

      return sorted;
    },

    status() {
      return customerStatus;
    },

    calcStagePercentages() {
      const total = this.statement.reduce((sum, item) => sum + item.value, 0);
      return this.statement.map((item) => {
        const percentage = ((item.value / total) * 100).toFixed(2);
        const remainingPercentage = (100 - percentage).toFixed(2);
        return {
          category: item.category,
          percentage: parseFloat(percentage),
          remainingPercentage: parseFloat(remainingPercentage),
        };
      });
    },
  },
  mounted() {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    this.createdAt = [
      firstDayOfYear.toISOString().split("T")[0],
      today.toISOString().split("T")[0],
    ];
    this.getSettingsTableKanban();
    this.fetchStatementData(this.createdAt[0], this.createdAt[1]);
    this.fetchKanbanData();
  },
  methods: {
    ...mapActions({
      getStatement: types.GET_BALANCE_STATEMENT,
      searchRecruitmentKanban: types.SEARCH_RECRUITMENT_KANBAN,
      searchRecruitmentTable: types.SEARCH_RECRUITMENT_TABLE,
      setFavorite: types.SET_FAVORITE,
      setCustomerLeadStatus: statusTypes.SET_CUSTOMER_LEAD_STATUS,
      downloadResume: statusTypes.DOWNLOAD_RESUME,
      openDisqualifyLeadSidebar: types.OPEN_DISQUALIFY_LEAD_SIDEBAR,
      getSettingsTableKanban: types.GET_SETTINGS_TABLE_KANBAN,
      openSaveConsultantBasicInformationSidebar: OPEN_NEW_CONSULTANT_SIDEBAR,
      getLeadData: types.GET_BALANCE_LEADDATA
    }),
    ...mapMutations({
      clearTableResults: types.MUTATE_CLEAR_RECRUITMENT_TABLE
    }),
    stagePercentage(stage) {
      const percentage =
        this.calcStagePercentages.find((p) => p.category === stage.stage_name)
          ?.percentage || 0;

      return [parseFloat(percentage), parseFloat(100 - percentage)];
    },
    handleDateChange(selectedDates) {
      const rangeDate = getRangeDates(selectedDates);
      if (rangeDate.start && rangeDate.end) {
        this.fetchStatementData(rangeDate.start, rangeDate.end);
      }
    },
    getStageKey(stageName) {
      const stageKeyMap = {
        Leads: "leads",
        "Road Show": "roads_show",
        "Dinâmica de Grupo": "dinamica_de_grupo",
        "Entrevista Individual": "entrevista_individual",
        "Pré-Job Sample": "pre_job_sample",
        "Job Sample": "job_sample",
        "Aprovação Final": "aprovacao_final",
        "Convertido em PN": "convertido_em_pn",
      };

      return (
        stageKeyMap[stageName] || stageName.toLowerCase().replace(/ /g, "_")
      );
    },

    async fetchStatementData(start_date, end_date) {
      this.loadingStatement = true;
      try {
        this.statement = await this.getStatement({ start_date, end_date });
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao trazer o balanço de recrutamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingStatement = false;
      }
    },
    fetchLeadData(activeTab){
      const {
        general,
        name,
        stage,
        status,
        origin,
        franchise,
        potential,
        createdAt,
        callReminder,
        structureOption,
        favorite,
      } = this.$refs.filters;
      this.loadingLeadDetails = true;
      const tab = activeTab ? activeTab : this.$refs.leadDetails.activeTab;
      this.getLeadData({
        filter_type: tab,
        general_search: general,
        name,
        stage,
        status,
        origin,
        franchise,
        potential,
        createdAt,
        callReminder,
        structureOption,
        favorite
      }) 
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao trazer a lista de planejadores. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingLeadDetails = false;
      });
    },
    setView(view) {
      this.view = view;
      if (view === "kanban") {
        this.fetchKanbanData();
      } else {
        this.fetchTableData();
      }
    },

    fetchKanbanData() {
      this.loading = true;
      const {
        general,
        name,
        stage,
        status,
        origin,
        franchise,
        potential,
        createdAt,
        callReminder,
        structureOption,
        favorite,
      } = this.$refs.filters;

      const call_reminder = getRangeDates(callReminder);
      const created_at = getRangeDates(createdAt);

      this.searchRecruitmentKanban({
        general_search: general,
        name,
        status,
        stage,
        origin,
        structure_option: structureOption,
        franchise,
        potential,
        favorite,
        call_reminder_start: call_reminder.start,
        call_reminder_end: call_reminder.end,
        created_at_start: created_at.start,
        created_at_end: created_at.end,
        limit: this.limits,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao trazer a lista de planejadores. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchTableData() {
      this.loading = true;
      const {
        general,
        name,
        stage,
        status,
        origin,
        franchise,
        potential,
        createdAt,
        callReminder,
        structureOption,
        favorite,
      } = this.$refs.filters;

      const call_reminder = getRangeDates(callReminder);
      const created_at = getRangeDates(createdAt);
      const stages = [1, 2, 3, 4, 5, 6, 7, 8];
      const uniStage = stage;

      if (uniStage) {
        const payload = {
          general,
          stage: uniStage,
          name,
          call_reminder_start: call_reminder.start,
          call_reminder_end: call_reminder.end,
          created_at_start: created_at.start,
          created_at_end: created_at.end,
          status,
          structure_option: structureOption,
          franchise,
          potential,
          origin,
          favorite,
          order: this.order,
          limit: 10,
          currentPage: 1,
        };
        this.clearTableResults();
        this.searchRecruitmentTable(payload)
          .catch((error) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao trazer a tabela de planejadores. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const promises = stages.map((stage) => {
          const payload = {
            general,
            stage: stage,
            name,
            call_reminder_start: call_reminder.start,
            call_reminder_end: call_reminder.end,
            created_at_start: created_at.start,
            created_at_end: created_at.end,
            status,
            structure_option: structureOption,
            franchise,
            potential,
            origin,
            favorite,
            order: this.order,
            limit: 10,
            currentPage: 1,
          };
          return this.searchRecruitmentTable(payload);
        });
        Promise.all(promises)
          .catch((error) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao trazer a tabela de planejadores. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handlePageChange(stageId, page) {
      this.fetchTableDataForStage(stageId, page);
    },

    async fetchTableDataForStage(stageId, page = 1) {
      this.loading = true;
      const payload = {
        stage: stageId,
        name: "",
        call_reminder_start: null,
        call_reminder_end: null,
        created_at_start: null,
        created_at_end: null,
        status: [],
        source: [],
        structure_option: "structure",
        franchise: [],
        potential: [],
        order: this.order,
        limit: 10,
        currentPage: page,
      };

      try {
        await this.searchRecruitmentTable(payload);
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao trazer a tabela de planejadores. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loading = false;
      }
    },

    toggleStatus(status_id, customer_id) {
      status_id === "hot"
        ? (status_id = this.status.CUSTOMER_STATUS_LEAD_PN_HOT)
        : status_id === "cold"
        ? (status_id = this.status.CUSTOMER_STATUS_LEAD_PN_COLD)
        : (status_id = this.status.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED);

      this.setCustomerLeadStatus({
        customer_id,
        status_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Status atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.fetchData();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar o status. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    async toggleFavorite(favorite, customer_id) {
      favorite = favorite === 0 ? 1 : 0;
      this.loading = true;
      try {
        await this.setFavorite({
          favorite,
          customer_id,
        });
        this.fetchData();
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao atualizar o favorito. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loading = false;
      }
    },

    toggleSelect(customer_id) {
      this.$store.commit(types.TOGGLE_LEAD_SELECTION, customer_id);
    },

    increaseLimit(stageId) {
      const stageLimit = this.limits.find(
        (limit) => limit.stage_id === stageId
      );
      if (stageLimit) {
        stageLimit.limit += 5;
        this.fetchKanbanData();
      }
    },
    resumeDownload(customer_id) {
      this.downloadResume(customer_id)
        .then((response) => {
          setTimeout(() => {
            window.open(response.data, "_blank").focus();
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    disqualifyLead(id, customer_name) {
      this.openDisqualifyLeadSidebar({
        id,
        customer_name,
        saveAction: () => {
          this.fetchData();
        },
      });
    },
    fetchData() {
      if (this.view === "kanban") {
        this.fetchKanbanData();
      } else {
        this.fetchTableData();
      }
      this.fetchLeadData();
    },
    convertToPn(customer_id, approval_date) {
      this.openSaveConsultantBasicInformationSidebar({
        id: undefined,
        customer_id,
        approval_date,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.height-card {
  max-height: 460px !important;
  overflow-y: auto;
}
.w-30 {
  width: 30%;
}
.container-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.kanban-board {
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: end;
}

.tabs-group {
  width: fit-content;
  display: flex;
  background-color: #283046;
  border-radius: 10px;
}

.tabs-group-dark {
  background-color: #283046;
}

.tabs-group-light {
  background-color: #ffffff;
  .tab-button {
    color: #657c87 !important;
  }
}

.tab-button {
  background-color: transparent;
  border: none;
  color: #cbd5e1;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.tab-button:hover {
  background-color: #334155;
}

.tab-button.active {
  background-color: #ea580c;
  color: #fff !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
