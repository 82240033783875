<template>
  <div class="w-100">
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    statement: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultColor: "#b4b7bd",
    };
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            borderRadiusApplication: "around",
            borderRadiusWhenStacked: "last",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 32,
          style: {
            fontSize: "12px",
          },
        },
        xaxis: {
          type: "category",
          categories: this.statement.map((item) => item.category),
          labels: {
            style: {
              colors: this.skin === "dark" ? this.defaultColor : "",
            },
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: this.skin === "dark" ? this.defaultColor : "",
              },
            },
            labels: {
              style: {
                colors: this.skin === "dark" ? this.defaultColor : "",
              },
            },
          },
        ],

        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#546E7A",
          "#26a69a",
          "#D10CE8",
        ],
      };
    },
    series() {
      return [
        {
          name: "Quantidade",
          data: this.statement.map((item) => item.value),
        },
      ];
    },
  },
  mounted() {
    this.chartUpdate();
  },
  methods: {
    chartUpdate() {
      this.chartOptions.xaxis.categories = this.statement.map(
        (item) => item.category
      );
      this.series[0].data = this.statement.map((item) => item.value);
    },
  },
  watch: {
    statement: {
      handler() {
        this.chartUpdate();
      },
      deep: true,
    },
  },
};
</script>
