<template>
  <div>
    <b-sidebar
      id="sidebar-settings-table-kanban"
      sidebar-class="sidebar-lg"
      :visible="settingsTableSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateSettingsTableSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 class="mb-0">Personalizar {{ view }}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form
          v-if="settings"
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          Personalize os campos
          {{ view === "kanban" ? "do seu kanban" : "da sua tabela" }}
          <br />
          <!-- Kanban Columns -->
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.leads" switch inline>
              Leads
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.roads_show" switch inline>
              Roads Show
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.dinamica_de_grupo" switch inline>
              Dinâmica de Grupo
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox
              v-model="settings.entrevista_individual"
              switch
              inline
            >
              Entrevista Individual
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.pre_job_sample" switch inline>
              Pré-Job Sample
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.job_sample" switch inline>
              Job Sample
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.aprovacao_final" switch inline>
              Aprovação Final
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.convertido_em_pn" switch inline>
              Convertido em PN
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? "Salvando sua personalização..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BFormCheckbox,
  BCardText,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import MoneyInput from "@/modules/shared/components/MoneyInput";
import * as types from "../store/types";
import BCardCode from "@core/components/b-card-code";
import { viewport } from "@popperjs/core";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BCol,
    BCardCode,
    vSelect,
    MoneyInput,
    BFormCheckbox,
    BCardText,
  },
  props: {
    searchKanban: {
      type: Function,
      default: () => {},
    },
    searchTable: {
      type: Function,
      default: () => {},
    },
    view: {
      type: String,
      default: "kanban",
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      settings: undefined,
    };
  },
  computed: {
    ...mapGetters({
      settingsTableSidebar: types.SETTINGS_TABLE_KANBAN_SIDEBAR,
      settingsTableKanban: types.SETTINGS_TABLE_KANBAN,
    }),
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  watch: {
    settingsTableKanban() {
      this.settings = { ...this.settingsTableKanban };
    },
  },
  mounted() {
    this.getSettingsTableKanban().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao carregar as configurações. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
  },
  methods: {
    ...mapMutations({
      mutateSettingsTableSidebar: types.MUTATE_SETTINGS_TABLE_KANBAN_SIDEBAR,
    }),
    ...mapActions({
      saveSettingsTableSidebar: types.SAVE_SETTINGS_TABLE_KANBAN,
      getSettingsTableKanban: types.GET_SETTINGS_TABLE_KANBAN,
    }),
    onSubmit() {
      this.saving = true;
      this.saveSettingsTableSidebar(this.settings)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A sua personalização foi salva com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateSettingsTableSidebar({ visible: false });
          this.searchKanban();
          this.searchTable();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar as configurações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
