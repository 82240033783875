<template>
  <b-sidebar
    id="sidebar-tags"
    :visible="leadTagSidebar.visible"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => mutateLeadTagSidebar({ visible: val })"
    @shown="onShow"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4>TAGS</h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Form Content -->
      <b-form @submit.prevent="onSave" class="p-1">
        <!-- Input to Add a New Tag -->
        <b-form-group label="Adicionar Nova TAG">
          <b-input-group>
            <b-form-input
              v-model="newTag"
              placeholder="Digite uma tag"
            />
            <b-input-group-append>
              <b-button 
                variant="primary" 
                @click="onAddTag"
                :disabled="loadingTags"
              >
                Adicionar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <div class="invalid-feedback" v-if="v$.newTag.$error">
            <span v-if="v$.newTag.required.$invalid">
              Você deve digitar algo antes de adicionar
            </span>
          </div>
        </b-form-group>

        <!-- Display Current Tags -->
        <div class="my-2">
          <h6>TAGS Atuais</h6>
          <div class="d-flex justify-content-center m-2" v-if="loadingTags">
            <b-spinner variant="primary" />
          </div>
          <b-list-group v-else>
            <b-list-group-item
              v-for="(tag, index) in currentTags"
              :key="tag.id"
              class="d-flex justify-content-between align-items-center"
            >
              <span>#{{ tag.name }}</span>
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer"
                @click="removeTag(index)"
              />
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- Buttons -->
        <div class="d-flex justify-content-between mt-2">
          <b-button
            variant="outline-primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="hide"
          >
            Voltar
          </b-button>
          <b-button
            type="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Salvar
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BListGroup,
  BListGroupItem,
  BInputGroup,
} from "bootstrap-vue";
import { required } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { mapGetters, mapActions, mapMutations } from "vuex";
import * as types from "../store/types";
import useVuelidate from "@vuelidate/core";

export default {
  name: "LeadTagSidebar",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BListGroup,
    BListGroupItem,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newTag: "",
      currentTags: [],
      loadingTags: false,
    };
  },
  computed: {
    ...mapGetters({
      leadTagSidebar: types.LEAD_TAG_SIDEBAR, 
    }),
  },
  validations() {
    return {
      newTag: { required },
    };
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();
    return { toast, v$ };
  },
  methods: {
    ...mapActions({
      getCustomerTags: types.GET_CUSTOMER_TAGS,
      createOrFetchTag: types.CREATE_OR_FETCH_TAG,
      removeTagFromApi: types.REMOVE_TAG,
    }),
    ...mapMutations({
      mutateLeadTagSidebar: types.MUTATE_LEAD_TAG_SIDEBAR
    }),
    onShow(){
      this.fetchCurrentTags()
    },
    async fetchCurrentTags() {
      this.loadingTags = true;
      try {
        const tags = await this.getCustomerTags(this.leadTagSidebar.customer_id);
        this.currentTags = tags;
      } catch (err) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Não foi possível carregar as tags do lead.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingTags = false;
      }
    },

    async onAddTag() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      const trimmedTag = this.newTag.trim();
      if (!trimmedTag) return;

      // Prevent duplicate tags locally
      const exists = this.currentTags.some(
        (tag) => tag.name.toLowerCase() === trimmedTag.toLowerCase()
      );
      if (exists) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção",
            text: "Esta TAG já foi adicionada.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const createdTag = await this.createOrFetchTag({
          customer_id: this.leadTagSidebar.customer_id,
          name: trimmedTag,
        });
        this.currentTags.push(createdTag);
        this.newTag = "";
        this.v$.$reset();
      } catch (err) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Erro",
            text: "Ocorreu um erro ao criar/fetch a TAG. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    removeTag(index) {
      const tag = this.currentTags[index];
      this.loadingTags = true
      this.removeTagFromApi(tag.id)
        .then(() => {
          this.currentTags.splice(index, 1);
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Erro",
              text: "Não foi possível remover a TAG. Entre em contato com o setor de TI",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingTags = false
        });
    },

    async onSave() {
      this.mutateLeadTagSidebar({ visible: false})
      this.leadTagSidebar.saveAction();
    },

    clearForm() {
      this.newTag = "";
      this.currentTags = [];
      this.mutateLeadTagSidebar({customer_id: undefined})
      this.v$.$reset();
    },
  },
};
</script>