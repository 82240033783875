<template>
  <b-sidebar
    id="sidebar-disqualify"
    :visible="disqualifyLeadSidebar.visible"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    backdrop
    no-header
    right
    @change="(val) => mutateDisqualifyLeadSidebar({ visible: val })"
    @hidden="clearForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4>Desclassificar Lead</h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form @submit.prevent="onSave" class="p-2">
        <!-- Lead Name (read-only) -->
        <b-form-group label="Lead">
          <b-form-input :value="disqualifyLeadSidebar.customer_name" disabled  />
        </b-form-group>

        <!-- Disqualification Reason (select) -->
        <b-form-group label="Qual o Motivo da Desclassificação?">
          <b-form-select
            v-model="selectedReason"
            :options="reasons"
            :text-field="'name'"
            :value-field="'id'"
            :disabled="reasonsLoading"
            placeholder="Escolha o motivo da desclassificação"
            required
          />
        </b-form-group>

        <!-- Additional comment -->
        <b-form-group label="Escrevo o motivo">
          <b-form-textarea
            v-model="comment"
            rows="3"
            placeholder="Insira o motivo da desclassificação"
          />
        </b-form-group>

        <!-- Buttons -->
        <div class="d-flex justify-content-between mt-2">
          <b-button
            variant="outline-primary"
            @click="hide"
          >
            Voltar
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            :disabled="saving"
          >
            {{ saving ? "Salvando..." : "Salvar" }}
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BFormTextarea } from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import * as types from '../store/types'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'LeadDisqualifySidebar',

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
  },

  data() {
    return {
      reasons: [],
      reasonsLoading: false,
      selectedReason: null,
      comment: '',
      saving: false,
    }
  },

  setup() {
    const toast = useToast()
    return { toast }
  },

  mounted() {
    this.fetchReasons()
  },
  computed: {
    ...mapGetters({
      disqualifyLeadSidebar: types.DISQUALIFY_LEAD_SIDEBAR
    })
  },
  methods: {
    ...mapMutations({
      mutateDisqualifyLeadSidebar: types.MUTATE_DISQUALIFY_LEAD_SIDEBAR
    }),
    ...mapActions({
      getDisqualifiedReasons: types.GET_DISQUALIFIED_REASONS,
      disqualifyLead: types.DISQUALIFY_LEAD,
    }),
    
    onChangeSidebar(newVal) {
      if (!newVal) {
        this.clearForm()
      }
    },

    async fetchReasons() {
      this.reasonsLoading = true
      try {
        const data = await this.getDisqualifiedReasons()
        this.reasons = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Não foi possível carregar os motivos de desclassificação. Entre em contato com o setor de TI',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.reasonsLoading = false
      }
    },

    async onSave() {
      if (!this.selectedReason) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atenção',
            text: 'Você deve selecionar um motivo.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      this.saving = true
      this.disqualifyLead({
        recruitment_id: this.disqualifyLeadSidebar.id,
        reason_id: this.selectedReason,
        comment: this.comment,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: 'Lead desclassificado com sucesso!',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.mutateDisqualifyLeadSidebar({ visible: false })
          this.disqualifyLeadSidebar.saveAction()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              text: 'Não foi possível desclassificar o lead. Entre em contato com o setor de TI',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },

    clearForm() {
      this.selectedReason = null
      this.comment = ''
    },
  },
}
</script>
